import { routes } from './routes';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { HttpModule } from '@angular/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { VehicleTypeComponent } from './vehicle-type/vehicle-type.component';
import { CarPartsComponent } from './car-parts/car-parts.component';
import { MotoPartsComponent } from './moto-parts/moto-parts.component';
import { DamagePicturesComponent } from './damage-pictures/damage-pictures.component';
import { DocumentPicturesComponent } from './document-pictures/document-pictures.component';
import { DocumentPicturesHelpComponent } from './document-pictures-help/document-pictures-help.component';
import { FinalPageComponent } from './final-page/final-page.component';
import { RepairCentersPageComponent } from './repaircenters-page/repaircenters-page.component';
import { InformationFormComponent } from './information-form/information-form.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import VehicleSidePicturesComponent from './vehicle-side-pictures/vehicle-side-pictures.component';
import { SidePicturesComponent } from './side-pictures/side-pictures.component';
import { MotoSidePicturesComponent } from './moto-side-pictures/moto-side-pictures.component';
import { SignaturePageComponent } from './signature-page/signature-page.component';
import { RemoteLibraryService, RemoteLibraryModule } from 'remote-library';
import { SignaturePadModule } from 'angular2-signaturepad';
import { RouterModule } from '@angular/router';
import { FeedbackPageComponent } from './feedback-page/feedback-page.component';
import { environment } from '../../../remote-library/src/environments/environment';
import { LoaderComponent } from './loader/loader.component';
import { ErrorNotificationComponent } from './error-notification/error-notification.component';
import { SidePicturesNoiaComponent } from './side-pictures-noia/side-pictures-noia.component';
import { MotoSidePicturesNoiaComponent } from './moto-side-pictures-noia/moto-side-pictures-noia.component';
import { NavbarComponent } from './navbar/navbar.component';
import { UseConditionsComponent } from './use-conditions/use-conditions.component';
import { SafePipe } from './safe.pipe';
import { ParentFilterPipe } from './parentFilter.pipe';
import { DamageQuestionComponent } from './damage-question/damage-question.component';
import { FullScreenGuard } from '../guards/full-screen.guard';
import { StatusGuard } from '../guards/status.guard';
import { MomentModule } from 'ngx-moment';
import { CancelledViewComponent } from './cancelled-view/cancelled-view.component';
import { HttpClientModule } from '@angular/common/http';
import { InlineSVGModule } from 'ng-inline-svg';
import { WarningBrowserComponent } from './warning-browser/warning-browser.component';
import { WarningDeviceComponent } from './warning-device/warning-device.component';
import { PresenceInsuredComponent } from './presence-insured/presence-insured.component';
import { VehiclePartsComponent } from './vehicle-parts/vehicle-parts.component';
import { ClaimDamagesComponent } from './claim-damages/claim-damages.component';
import { ClaimDamagesGalleryComponent } from './claim-damages/gallery/claim-damages-gallery.component';
import { EnvironmentalCaptureWrapperComponent } from './environmental-capture/environmental-capture-wrapper.component';
import { EnvironmentalCaptureComponent } from './environmental-capture/environmental-capture/environmental-capture.component';
import { EnvironmentalCaptureZoneComponent } from './environmental-capture/environmental-capture-zone/environmental-capture-zone.component';
import { EnvironmentalCaptureZoneStepComponent } from './environmental-capture/environmental-capture-zone-step/environmental-capture-zone-step.component';
import { DocumentFiles } from './document-files/document-files.component';
import { MatIconModule } from '@angular/material/icon';
import { WarningLuminosityComponent } from './shared/warning-luminosity/warning-luminosity.component';
import { WarningPhotoComponent } from './shared/warning-photo/warning-photo.component';
// Errors
import { SelfadjustErrorLayoutComponent } from './layout/selfadjust-error-layout/selfadjust-error-layout.component';
import { PermissionCameraErrorComponent } from './error/permission-camera-error/permission-camera-error.component';
import { PermissionLocationErrorComponent } from './error/permission-location-error/permission-location-error.component';
import { NoCameraErrorComponent } from './error/no-camera-error/no-camera-error.component';
import { GenericCameraErrorComponent } from './error/generic-camera-error/generic-camera-error.component';
import { InUseCameraErrorComponent } from './error/in-use-camera-error/in-use-camera-error.component';
import { GenericErrorComponent } from './error/generic-error/generic-error.component';
import { SamsungPermissionCameraErrorComponent } from './error/samsung-permission-camera-error/samsung-permission-camera-error.component';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { DocumentFileComponent } from './document-files/document-file/document-file.component';
import { InformationPageComponent } from './information-page/information-page.component';
import { WarningBlockedComponent } from './shared/warning-blocked/warning-blocked.component';
import { TruckSidePicturesComponent } from './truck-side-pictures/truck-side-pictures.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    VehicleTypeComponent,
    CarPartsComponent,
    MotoPartsComponent,
    DamagePicturesComponent,
    DocumentPicturesComponent,
    DocumentPicturesHelpComponent,
    FinalPageComponent,
    RepairCentersPageComponent,
    InformationFormComponent,
    LandingPageComponent,
    SidePicturesComponent,
    MotoSidePicturesComponent,
    MotoSidePicturesNoiaComponent,
    TruckSidePicturesComponent,
    VehicleSidePicturesComponent,
    SignaturePageComponent,
    FeedbackPageComponent,
    LoaderComponent,
    ErrorNotificationComponent,
    SidePicturesNoiaComponent,
    NavbarComponent,
    UseConditionsComponent,
    SafePipe,
    ParentFilterPipe,
    DamageQuestionComponent,
    CancelledViewComponent,
    PermissionCameraErrorComponent,
    WarningBrowserComponent,
    WarningDeviceComponent,
    PresenceInsuredComponent,
    VehiclePartsComponent,
    ClaimDamagesComponent,
    ClaimDamagesGalleryComponent,
    EnvironmentalCaptureWrapperComponent,
    EnvironmentalCaptureComponent,
    EnvironmentalCaptureZoneComponent,
    EnvironmentalCaptureZoneStepComponent,
    DocumentFiles,
    WarningLuminosityComponent,
    SelfadjustErrorLayoutComponent,
    PermissionLocationErrorComponent,
    NoCameraErrorComponent,
    GenericCameraErrorComponent,
    InUseCameraErrorComponent,
    GenericErrorComponent,
    SamsungPermissionCameraErrorComponent,
    WarningPhotoComponent,
    WarningBlockedComponent,
    NotFoundComponent,
    DocumentFileComponent,
    InformationPageComponent,
  ],
  imports: [
    RouterModule.forRoot(routes, {useHash: true}),
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCb21AAd6wGegd2izUTHHmqfLfr8TbniAg'
    }),
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    SignaturePadModule,
    MomentModule,
    HttpClientModule,
    NgSelectModule,
    InlineSVGModule.forRoot(),
    RemoteLibraryModule,
  ],
  providers: [
    RemoteLibraryService,
    FullScreenGuard,
    StatusGuard,
  ],
  entryComponents: [ClaimDamagesGalleryComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
