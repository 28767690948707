import { Component, Input } from "@angular/core";
import { HelpPageBlock } from "./models/help-page-block";

@Component({
  selector: 'app-document-pictures-help',
  templateUrl: './document-pictures-help.component.html',
  styleUrls: ['./document-pictures-help.component.scss']
})
export class DocumentPicturesHelpComponent {
  @Input() public blocks: HelpPageBlock[];
}
