import { Component, OnInit, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { RemoteLibraryService } from 'remote-library';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelpPageBlock } from '../document-pictures-help/models/help-page-block';

type DocumentConfig = {
  id: string;
  name: string;
  name_es?: string;
  type: string;
  capture: 'camera' | 'none';
  image: string;
  bgImage: string;
  isBGShow: boolean;
  loading: boolean;
  auxId: string;
  mandatory: boolean;
  detectDamagesOnlyIn?: string[];
  isFullfiled?: boolean;
  fix?: boolean;
  helpPage?: HelpPageBlock[];
}

const DEFAULT_DOC_CONFIG: DocumentConfig[] = [
  {
    id: 'policy',
    name: 'Policy',
    name_es: 'Póliza',
    type: 'policy',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_policy',
    mandatory: true
  },
  {
    id: 'license',
    name: 'Driver license',
    name_es: 'Licencia de conducir',
    type: 'license',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_license',
    mandatory: true
  },
  {
    id: 'plate',
    name: 'Plate',
    name_es: 'Placa',
    type: 'registration',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_plate',
    mandatory: true
  },
  {
    id: 'vin',
    name: 'VIN',
    name_es: 'VIN',
    type: 'vin',
    capture: 'camera',
    image: null,
    bgImage: '../../assets/images/logo/camera.svg',
    isBGShow: true,
    loading: false,
    auxId: 'aux_vin',
    mandatory: true
  }
];

@Component({
  selector: 'app-document-pictures',
  templateUrl: './document-pictures.component.html',
  styleUrls: [
    './document-pictures.component.scss'
  ]
})
export class DocumentPicturesComponent implements OnInit, AfterViewInit {
  @Output() public onChangedNumImgDoc = new EventEmitter<boolean>();
  public numImages: number;  
  public image: string;
  public actualSelfadjust: any;
  public vehicleType: string;
  public notfication = new Subject();
  public documentArray: DocumentConfig[];
  public loading = false;
  public loaded = false;
  public documentPictures: any;
  public nextAvailable = false;
  public openNotification = false;
  public showHelp = false;
  // Hacer esto genérico
  public showHelpInterior = false;
  public showHelpDisk = false;
  public showHelpOdometer = false;
  public showHelpTires = false;
  public showHelpWindscreen = false;
  // Fin ayudas Hollard
  public targetName: string;
  public photosQuantity: number;
  public unfixed = false;
  public unfixedArray: boolean[];
  public isIos = navigator.userAgent.indexOf('iPhone') >= 0;
  public showLegalFooter: Boolean = null;
  public helpPage?: HelpPageBlock[];

  constructor(
    public remoteService: RemoteLibraryService,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.remoteService.selfAdjustService.reloadStylesFromComponent();

    if (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') {
      DEFAULT_DOC_CONFIG.forEach((config) => {
        config.bgImage = '/assets/images/logo/camera_new.svg';
      });
    }

    this.restoreHelp();

    this.loadDynamicStyles(this.remoteService.selfAdjustService.customStylesFolder);
    this.documentPictures = this.remoteService.selfAdjustService.myPage('document-pictures');

    if (this.documentPictures.documentArray) {
      this.documentArray = this.documentPictures.documentArray;
      if (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') {
        this.documentArray = this.documentArray.map((item) => {
          item.bgImage = '/assets/images/logo/camera_new.svg';
          return item;
        });
      }
      this.modifyPhotoPosition(this.photosQuantity);
    } else {
      this.documentArray = DEFAULT_DOC_CONFIG;
    }

    this.photosQuantity = this.documentPictures.photosQuantity ? this.documentPictures.photosQuantity : this.documentArray.length;
    this.nextAvailable = this.checkMandatoryFullfilled();
    this.numImages = 0;
    this.actualSelfadjust = this.remoteService.selfAdjustService.actualSelfAdjust;
    this.vehicleType = this.actualSelfadjust.vehicleType;
    this.showLegalFooter = this.remoteService.selfAdjustService.actualCompany.showLegalFooter;

    // Comprobamos si es una reapertura pq documentPictures.fixed es false y asignamos array de elementos a arreglar
    if (this.documentPictures.fixed !== undefined && !this.documentPictures.fixed) {
      this.unfixed = true;
      this.unfixedArray = this.documentArray.map(item => !item.fix || this.documentPictures.fixed);
    } else {
      this.unfixed = false;
      this.unfixedArray = this.documentArray.map(item => true);
    }

    this.updatePage(this.actualSelfadjust);

    if (this.remoteService.selfAdjustService.logo_camera) {
      for (let i = 0; i < this.documentArray.length; i++) {
        this.documentArray[i].bgImage = this.remoteService.selfAdjustService.logo_camera;
      }
    }
  }

  ngAfterViewInit() {
    if (this.documentPictures.documentArray) {
      this.changePhotoUpload();
    }

    this.loaded = true;
  }

  loadDynamicStyles(customStylesFolder) {
    if (customStylesFolder) {
      if (customStylesFolder === 'Banorte'
        || customStylesFolder === 'banorteIV') {
        document.getElementsByTagName('app-document-pictures')[0]['style'].position = 'fixed';
      }

      try {
        require(`style-loader!./customStyles/${customStylesFolder}/customStyle.scss`);
      } catch (error) {
        console.log(error)
      }
    }
  }

  getTitleName(name) {
    return this.remoteService.selfAdjustLanguageService.strLang(name) ? this.remoteService.selfAdjustLanguageService.strLang(name) : name;
  }

  checkMandatoryFullfilled() {
    let mandatoryFullfilled = true;

    for (const img of this.documentArray) {
      if (img.mandatory && !img.isFullfiled) {
          mandatoryFullfilled = false;
          break;
      }
    }

    return this.unfixedArray ? !this.unfixedArray.includes(false) && mandatoryFullfilled : mandatoryFullfilled;
  }

  changePhotoUpload() {
    this.documentArray.forEach(e => {
      if (e.capture === 'none') {
        document.getElementById(e.id).removeAttribute('capture');
      }
    });
  }

  modifyPhotoPosition(photosNumber) {
    const resizePosition = document.getElementsByClassName('fitImg')[0];

    if (photosNumber === 1) {
      resizePosition['style']['justify-content'] = 'center';
      resizePosition['style']['align-content'] = 'center';
    } else if (photosNumber === 2) {
      resizePosition['style']['justify-content'] = 'space-between';
    }
  }

  async imageUpload(file, imageType, index) {
    console.log('Trying to upload new file...');
    console.log(`Image type: ${imageType}`);
    console.log(`Index: ${index}`);

    this.restoreHelp();
    const self = this;
    const coordinates = await this.remoteService.selfAdjustService.getCoordinates();
    
    this.documentArray[index].loading = true;
    this.cd.detectChanges();
    this.remoteService.selfAdjustService.resizeAndDraw(file, 1280).then(result => {
      const contentType = result.slice(result.indexOf('image/'), result.indexOf(';'));
      const extension = contentType.split('image/')[1];
      const caption = this.documentArray[index].name;

      const newImg = {
        name: result,
        type: imageType,
        caption
      };

      this.remoteService.selfAdjustService.getMediaUrl(this.actualSelfadjust.id, extension).pipe(take(1)).subscribe(media => {
        this.remoteService.selfAdjustService.bucketImage(result, media['media_url'], contentType).pipe(take(1)).subscribe(response => {
          const logInfo = {
            component: 'document-pictures',
            action: 'push-image'
          };
          const detectDamagesOnlyIn = this.documentArray[index].detectDamagesOnlyIn;
          console.log('detectDamagesOnlyIn', detectDamagesOnlyIn);
          this.remoteService.selfAdjustService.addImage(
            this.actualSelfadjust.id, this.actualSelfadjust['securityKey'], media['media_id'],
            imageType, extension, logInfo, coordinates, caption, null, null, null, detectDamagesOnlyIn).subscribe(e => {
              this.remoteService.selfAdjustService.updateLocalImages(newImg);
              self.documentArray[index].image = result;
              self.documentArray[index].isBGShow = false;
              self.documentArray[index].isFullfiled = true;
              self.numImages = self.numImages + 1;
              this.documentArray[index].loading = false;
              if (this.documentPictures.fixed !== undefined && !this.documentPictures.fixed) {             
                this.isFixed(index);
              }
              self.nextAvailable = this.checkMandatoryFullfilled();
              file.value = '';
              this.cd.detectChanges();
            },
          err => {
            console.log('Error in addImage', err);
            this.showNotification(index);
          });
        },
        err => {
          console.log('Error in bucketImage', err);
          this.showNotification(index);
        });
      },
      err => {
        console.log('Error in getMediaUrl', err);
        this.showNotification(index);
      });
    });
  }

  /**
   * Devuelve a su valor inicial las ayudas de la toma de fotos
   */
  restoreHelp() {
    this.helpPage = undefined;
    this.showHelp = false;
    this.showHelpOdometer = false;
    this.showHelpInterior = false;
    this.showHelpDisk = false;
    this.showHelpTires = false;
    this.showHelpWindscreen = false;
  }

  isFixed(index) {
    this.unfixedArray[index] = true;

    if (!this.unfixedArray.includes(false)) {
      this.unfixed = false;
      this.remoteService.selfAdjustService.fixedPage('document-pictures');
    }
  }
  
  onClick (event, carimage) {
    console.log(`Function onClick.event: ${ event }`);
    console.log(`Function onClick.carimage: ${ JSON.stringify(carimage) }`);

    if (carimage.fix == undefined || carimage.fix) {
      this.targetName = carimage.id;
      this.helpPage = carimage.helpPage;

      if(this.helpPage) {
        return;
      }

      if (carimage.id.includes('vin') && this.documentPictures.vinHelp) {
        this.showHelp = true;
      } else if (carimage.id === 'milleage' || carimage.id === 'odometer') {
        this.showHelpOdometer = true;
      } else if (carimage.id === 'interior' && this.documentPictures.interiorHelp) {
        this.showHelpInterior = true;
      } else if (carimage.id === 'license_disc' && this.documentPictures.diskHelp) {
        this.showHelpDisk = true;
      } else if (carimage.id === 'tires' && this.documentPictures.tiresHelp) {
        this.showHelpTires = true;
      } else if (carimage.id === 'windscreen') {
        this.showHelpWindscreen = true;
      }
    }

    console.log(`targetImage: ${this.targetName}`);
  }

  checkForHelp(carimage){
    if(carimage.helpPage) {
      return true;
    }

    return carimage.id === 'milleage' || carimage.id === 'odometer'
    || this.documentPictures.interiorHelp && carimage.id === 'interior'
    || this.documentPictures.diskHelp && carimage.id === 'license_disc'
    || this.documentPictures.vinHelp && carimage.id.includes('vin')
    || this.documentPictures.tiresHelp && carimage.id === 'tires'
    || carimage.id === 'windscreen';
  }

  closeHelp() {
    this.helpPage = undefined;
    this.showHelp = false;
  }

  closeHelpOdometer() {
    this.showHelpOdometer = false;
  }

  closeHelpInterior() {
    this.showHelpInterior = false;
  }

  closeHelpDisk() {
    this.showHelpDisk = false;
  }

  closeHelpTires() {
    this.showHelpTires = false;
  }

  closeHelpWindscreen() {
    this.showHelpWindscreen = false;
  }

  openCamera() {
    console.log('Trying click: ', this.targetName);

    try {
      document.getElementById(`${this.targetName}`).click();
    } catch(e) {
      console.log('Error: ', e);
    }
  }

  showNotification(index) {
    this.openNotification = true;

    setTimeout(() => {
      this.openNotification = false;
      if (typeof index === 'number') this.documentArray[index].loading = false;
      this.loading = false;
      this.cd.detectChanges();

    }, 5000);

    this.cd.detectChanges();
  }

  updatePage(actualSelfadjust) {
    this.fillPictureDatas();

    console.log("actualSelfadjust",actualSelfadjust)

    if (actualSelfadjust.images) {
      actualSelfadjust.images.map(async (e) => {
        this.documentArray.forEach((element) => {
          if (element.type === e.type && element.name === e.caption) {
            element.image = e.name;
            element.isBGShow = false;
            element.isFullfiled = true;
            this.numImages++;
          }
          if (element.fix && !this.remoteService.selfAdjustService.isPageFixed('document-pictures')) {
            delete element.image;
            element.isBGShow = true;
          }
        });
      });
    }

    this.nextAvailable = this.checkMandatoryFullfilled();
  }

  fillPictureDatas() {
    let aux = this.documentPictures.photosQuantity - this.documentArray.length;
    const pathDefaultCameraImg = (this.remoteService.selfAdjustService.customStylesFolder === 'bdeo') ? '/assets/images/logo/camera_new.svg' : '/assets/images/logo/camera.svg';
    const cameraLogoImg = this.remoteService.selfAdjustService.logo_camera ? this.remoteService.selfAdjustService.logo_camera : pathDefaultCameraImg;

    while (aux > 0) {
      let num = this.documentArray.length + 1;
      this.documentArray.push({
        id: `image${num}`,
        name: 'Document',
        type: `document_${num}`,
        capture: 'camera',
        image: null,
        bgImage: cameraLogoImg,
        isBGShow: true,
        loading: false,
        auxId: 'aux_document',
        mandatory: false
      });
      aux--;
    }
}

  movePage(action) {
    this.loading = true;

    if (action === 'next') {
      const data = {
        security_key: this.remoteService.selfAdjustService.secretKey,
        status: this.remoteService.selfAdjustService.AppStatus.inprocess,
        logInfo: {
          component: 'document-pictures',
          action: 'continue'
        },
        fields2Update: {}
      };

      if (this.remoteService.selfAdjustService.getReopenInfo() && this.documentPictures.fixed !== undefined) {
        data.fields2Update['reopenInfo'] = { pages: null };
        data.fields2Update['reopenInfo'].pages = this.remoteService.selfAdjustService.getReopenInfo();
      }

      this.remoteService.selfAdjustService.pushData(data).subscribe((res) => {
        console.log('INFO: Navega a:', this.remoteService.selfAdjustService.nextPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
        console.log('this.remoteService.selfAdjustService.nextPage', this.remoteService.selfAdjustService.nextPage)
        this.router.navigate(
          [...this.remoteService.selfAdjustService.nextPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]
        );
      },

      err=>{
        console.log("Error in selfadjust-update",err);
        let index = undefined;
        this.showNotification(index);
      });
    } else {
      console.log('INFO: Navega a:', this.remoteService.selfAdjustService.backPage, 'con secretKey:', this.remoteService.selfAdjustService.secretKey);
      this.router.navigate([...this.remoteService.selfAdjustService.backPage, {secretKey: this.remoteService.selfAdjustService.secretKey}]);
    }
  }
}
